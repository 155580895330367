const firebaseConfig = {
    apiKey: "AIzaSyBuj3nXGF2Ezdu-dh2_OOqW-QGZ6Fxr5Fc",
    authDomain: "maklerfrei-app-8fc2b.firebaseapp.com",
    projectId: "maklerfrei-app-8fc2b",
    storageBucket: "maklerfrei-app-8fc2b.firebasestorage.app",
    messagingSenderId: "90539668093",
    appId: "1:90539668093:web:7b1227759d83cf6048b714",
    measurementId: "G-8HDT5EEM1X"
};

export default firebaseConfig;